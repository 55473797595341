import React from "react";
import LoaderAnimation from "assets/animations/loader.json";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface ILoadingProps {
  animation?: string;
}

export const Loading: React.FC = (props: ILoadingProps) => {
  const { theme } = useSelector((state: RootState) => state.theme);
  return (
    <div
      className={`${
        theme === 'dark' ? 'blackBg' : 'lightBg'
      } w-full h-screen flex justify-center items-center`}
    >
      <div className='w-72 h-1/12'>
        <Lottie
          options={{
            animationData: props?.animation ?? LoaderAnimation,
          }}
        />
      </div>
    </div>
  );
};
