import { takeLatest, put, call } from 'redux-saga/effects';
import { authURL, axiosInstance } from 'config';
import { apiURL } from 'config';
import toast from 'react-hot-toast';

import {
  loginRequested,
  loginSuccess,
  loginFailure,
  registerRequested,
  registerSuccess,
  registerFailure,
  googleLoginRequested,
  googleLoginSuccess,
  googleLoginFailure,
  forgotPasswordRequested,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordRequested,
  resetPasswordSuccess,
  resetPasswordFail,
} from './authSlice';

function* loginEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${apiURL}/login`,
      action.payload
    );

    if (data?.status) {
      yield put(loginSuccess(data));
      toast.success('Login Succeed');
    } else {
      if (data?.errors) {
        toast.error(data?.errors[0]);
      } else {
        toast.error('Login Failed');
      }
    }
  } catch (error: any) {
    yield put(loginFailure(error.message));
  }
}

function* registerEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${authURL}/register`,
      action.payload
    );

    if (data?.status) {
      yield put(registerSuccess(data));
      toast.success('Registration Succeed');
    } else {
      yield put(registerFailure('Registration Failed'));
      if (data?.errors) {
        toast.error(data?.errors[0]);
      } else {
        toast.error('Registration Failed');
      }
    }
  } catch (error: any) {
    yield put(registerFailure(error.message));
  }
}

function* googleLoginEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${apiURL}/google-sso`,

      action.payload
    );
    if (data?.status) {
      yield put(googleLoginSuccess(data));
      toast.success('Successfully Logged In via SSO.');
    } else {
      toast.error(data?.errors);
    }
  } catch (error: any) {
    yield put(googleLoginFailure(error.message));
  }
}

function* forgotPasswordEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/forgot-password?email=${action.payload?.email}`
    );
    if (data?.status) {
      yield put(forgotPasswordSuccess(data));
      toast.success('Check your email to reset password.');
    } else {
      toast.error(data?.errors);
      yield put(forgotPasswordFailure(data?.errors));
    }
  } catch (error: any) {
    yield put(forgotPasswordFailure(error.message));
  }
}

function* resetPasswordEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${apiURL}/reset-password`,
      action.payload
    );
    if (data?.status) {
      yield put(resetPasswordSuccess(data));
      toast.success('Your password has been reset successfully.');
    } else {
      toast.error(data?.errors);
    }
  } catch (error: any) {
    yield put(resetPasswordFail(error.message));
  }
}

export function* authSaga(): Generator<any, void, any> {
  yield takeLatest(loginRequested, loginEffect);
  yield takeLatest(registerRequested, registerEffect);
  yield takeLatest(googleLoginRequested, googleLoginEffect);
  yield takeLatest(forgotPasswordRequested, forgotPasswordEffect);
  yield takeLatest(resetPasswordRequested, resetPasswordEffect);
}
