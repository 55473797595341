import { Button, Input } from 'components/shared';
import { FormikProps, withFormik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { object, string } from 'yup';

interface ISubscriptionProps {
  onSubmit?: any;
}

interface IFormValue {
  email: any;
}

function Subscription(props: FormikProps<IFormValue> & ISubscriptionProps) {
  const { subscription } = useSelector(
    (state: RootState) => state.subscription
  );

  React.useEffect(() => {
    if (subscription?.result) {
      props.setSubmitting(false);
      props.resetForm();
    }
  }, [subscription?.result]);

  return (
    <div className='xl:flex flex-col sm:flex-row md:justify-start items-center gap-2 w-full'>
      <form onSubmit={props.handleSubmit}>
        <div className='flex gap-2 w-full justify-start items-center'>
          <div className='w-72 h-10'>
            <Input
              name='email'
              type='text'
              onChange={props.handleChange}
              values={props.values}
              onBlur={props.handleBlur}
              placeholder='Enter Email'
              errors={props.errors}
              touches={props.touched}
            />
          </div>
          <div>
            <Button
              title='Subscribe'
              variant='medium'
              disabled={!props?.isValid}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default withFormik<ISubscriptionProps, IFormValue>({
  validationSchema: object().shape({
    email: string().email('Invalid Email Format').required('Email is required'),
  }),
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
  validateOnMount: true,
})(Subscription);
