import React from 'react';
import { Button, Input, NumberInput } from 'components/shared';
import { withFormik, FormikProps } from 'formik';
import { string, object } from 'yup';
import Chicken from 'assets/images/login/Chicken.png';
import GoogleLogin from '../GoogleLogin';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface ISignUpProps {
  onSubmit?: any;
  setAuthOpenQuery?: any;
}

interface IFormValue {
  firstname: string;
  lastname: string;
  password: string;
  email: string;
  mobile: string;
}

function SignUp(props: FormikProps<IFormValue> & ISignUpProps) {
  const { theme } = useSelector((state: RootState) => state.theme);

  const [showPassword, setShowPassword] = useState(false);

  const visiblePassword = () => {
    setShowPassword(!showPassword);
  };

  const { auth, registerFail } = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    props.resetForm();
    props.setSubmitting(false);
  }, [auth, registerFail]);

  return (
    <div className='p-4 md:p-6 max-w-4xl mx-auto rounded-lg shadow-md'>
      <div className='text-h4 md:text-h3 font-poppinsSemiBold text-center mb-6 text-red-600'>
        Join Crazy Fried Chicken Today!
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 items-center'>
        <div className='w-full flex justify-center'>
          <img
            src={Chicken}
            alt='login'
            className='max-w-full h-auto object-contain'
          />
        </div>

        <div className='space-y-4'>
          <form
            onSubmit={props.handleSubmit}
            className='w-full space-y-3'
          >
            <div className='space-y-1'>
              <Input
                name='email'
                label='Email'
                type='text'
                placeholder='Enter your Email'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name='firstname'
                label='First Name'
                type='text'
                placeholder='Enter First Name'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <Input
                name='lastname'
                label='Last Name'
                type='text'
                placeholder='Enter Last Name'
                values={props.values}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                errors={props.errors}
                touches={props.touched}
              />
              <NumberInput
                name='mobile'
                label='Mobile Number'
                placeholder='Enter your Mobile Number'
                values={props.values.mobile}
                onChange={(value) => {
                  props.setFieldValue('mobile', value);
                }}
                onBlur={() => {
                  props.setFieldTouched('mobile', true);
                }}
                errors={props.errors}
                touches={props.touched}
                disabled={false}
                defaultCountry='gb'
              />
              <div className='relative flex flex-col gap-2'>
                <Input
                  name='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Enter Password'
                  values={props.values}
                  onChange={props.handleChange}
                  errors={props.errors}
                  touches={props.touched}
                />
                <div
                  className='absolute right-3 top-10 cursor-pointer'
                  onClick={visiblePassword}
                >
                  {showPassword ? (
                    <FaEye className='text-black' />
                  ) : (
                    <FaEyeSlash className='text-black' />
                  )}
                </div>
              </div>
              <div className='pt-2'>
                <Button
                  title={props.isSubmitting ? 'Signing Up...' : 'Sign Up'}
                  type='submit'
                  variant='large'
                />
              </div>
            </div>

            <div className='flex justify-end items-center space-x-2 mt-4'>
              <div
                className={`text-p3 text-end ${
                  theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
                }`}
              >
                Already have an account?
              </div>
              <div
                className='text-p3 text-end text-red-600 cursor-pointer font-poppinsBold'
                onClick={() => {
                  setTimeout(() => props.setAuthOpenQuery('login'), 300);
                }}
              >
                Login
              </div>
            </div>
          </form>

          <div className='mt-6'>
            <GoogleLogin />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withFormik<ISignUpProps, IFormValue>({
  validationSchema: object().shape({
    firstname: string().required('First Name is required.'),
    lastname: string().required('Last Name is required.'),
    password: string()
      .required('Password is required.')
      .min(6, 'Password must be at least 6 characters.'),
    email: string()
      .required('Email is required')
      .email('Enter a valid email to get notified!'),
    mobile: string()
      .required('Mobile Number is required')
      .matches(/^(\d{12})$/, 'Invalid mobile number.'),
  }),
  mapPropsToValues: () => ({
    firstname: '',
    lastname: '',
    password: '',
    email: '',
    mobile: '',
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(SignUp);
