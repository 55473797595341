import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ClientRouter } from './router/Router';
import store from 'store/store';
import { PrimeReactProvider } from 'primereact/api';
import { Toaster } from 'react-hot-toast';
import { SiTicktick } from 'react-icons/si';
import { RiErrorWarningLine } from 'react-icons/ri';
import { HelmetProvider } from 'react-helmet-async';
import MetaTags from 'utils/meta/MetaTag';
import { ErrorBoundary } from 'components/base';

function App() {
  useEffect(() => {
    const checkAndUpdateStorage = () => {
      const currentTime = Date.now();
      const setupTime = localStorage.getItem('setupTime');
      const setupTimeNumber = setupTime ? parseInt(setupTime, 10) : null;

      if (!setupTimeNumber) {
        localStorage.setItem('setupTime', currentTime.toString());
      } else if (currentTime - setupTimeNumber > 12 * 60 * 60 * 1000) {
        localStorage.removeItem('persist:cart');
        localStorage.removeItem('persist:orderType');
        window.location.reload();

        localStorage.setItem('setupTime', currentTime.toString());
      }
    };
    const intervalId = setInterval(checkAndUpdateStorage, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <HelmetProvider>
          <MetaTags
            title='Crazy Fried Chicken'
            description='Savor the best crispy fried chicken! Crazy Fried Chicken offers mouthwatering flavors, golden-crispy perfection, and delicious sides. Perfect for dine-in, takeout, or delivery. Order now!'
            keywords='crispy fried chicken, flavors, dine-in, collection, delivery'
          />
          <PrimeReactProvider>
            <ClientRouter />
            <Toaster
              toastOptions={{
                success: {
                  className:
                    'text-green-900 text-xs font-semibold tracking-widest bg-green-100 w-auto',
                  icon: <SiTicktick className=' text-green-700  h-4 w-4' />,
                  position: 'bottom-right',
                  duration: 4000,
                },
                error: {
                  className:
                    'text-red-900 text-xs font-semibold tracking-widest bg-red-100 w-auto',
                  icon: (
                    <RiErrorWarningLine className=' text-red-800  h-5 w-5' />
                  ),
                  position: 'bottom-right',
                  duration: 4000,
                },
              }}
            />
          </PrimeReactProvider>
        </HelmetProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
