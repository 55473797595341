import React from 'react';
import Snowfall from 'react-snowfall';

function SnowFall() {
  return (
    <div>
      <Snowfall
        snowflakeCount={60}
        style={{
          position: 'fixed',
          zIndex: 1000,
          width: '100vw',
          height: '100vh',
        }}
      />
    </div>
  );
}

export default SnowFall;
