import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  deleteCartRequested,
  storeCartRequested,
  updateCartRequested,
} from 'store/cart/cartSlice';

export function useStoreCart() {
  const dispatch = useDispatch();

  const onStoreCart = useCallback(
    async (values: any) => {
      await dispatch(storeCartRequested(values));
    },
    [dispatch]
  );
  return { onStoreCart };
}


export function useUpdateCart() {
  const dispatch = useDispatch();

  const onUpdateCart = useCallback(
    async (id: any, type: any) => {
      await dispatch(updateCartRequested({ id, type }));
    },
    [dispatch]
  );

  return { onUpdateCart };
}

export function useDeleteCart() {
  const dispatch = useDispatch();

  const onDeleteCart = useCallback(
    async (id: number) => {
      await dispatch(deleteCartRequested(id));
    },
    [dispatch]
  );

  return { onDeleteCart };
}
