import React, { useEffect } from 'react';
import { Button, Input } from 'components/shared';
import Chicken from 'assets/images/login/Chicken.png';
import { withFormik, FormikProps } from 'formik';
import { string, object } from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface IForgotPasswordProps {
  onSubmit?: any;
  setAuthOpenQuery?: any;
}

interface IFormValue {
  email: string;
}

function ForgotPassword(props: FormikProps<IFormValue> & IForgotPasswordProps) {
  const { theme } = useSelector((state: RootState) => state.theme);
  const { forgotPassword, forgotPasswordFail } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    props.resetForm();
    props.setSubmitting(false);
  }, [forgotPassword, forgotPasswordFail]);

  return (
    <div className='h-fit w-full mx-auto px-4 pb-6 md:px-6 md:pb-10 lg:w-[50vw]'>
      <div className='text-h3 font-poppinsMedium text-center mb-6 text-pureWhite text-red-600'>
        Password Gone? Let’s Cook Up a Fresh One!
      </div>
      <div className='flex flex-col md:flex-row items-center md:space-x-8'>
        <div className='w-full md:w-1/2 h-[30vw] md:h-[40vw] lg:h-[35vw] max-h-80 flex justify-center mb-6 md:mb-0'>
          <img
            src={Chicken}
            alt='login'
            className='w-full h-full object-cover'
          />
        </div>
        <div className='w-full md:w-1/2'>
          <form
            onSubmit={props.handleSubmit}
            className='space-y-4'
          >
            <div className='space-y-4'>
              <Input
                name='email'
                label='Email'
                type='email'
                placeholder='Enter Email'
                values={props.values}
                onChange={props.handleChange}
                errors={props.errors}
                touches={props.touched}
              />

              <div
                className={`text-p3 tracking-wider text-right cursor-pointer ${
                  theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
                }`}
                onClick={() => {
                  setTimeout(() => props.setAuthOpenQuery('login'), 100);
                }}
              >
                Back to Login
              </div>

              <div className='flex justify-center'>
                <Button
                  title={props.isSubmitting ? 'Sending mail...' : 'Continue'}
                  type='submit'
                  variant='large'
                  disabled={props.isSubmitting}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withFormik<IForgotPasswordProps, IFormValue>({
  validationSchema: object().shape({
    email: string()
      .required('Enter the email to get reset link')
      .email('Invalid Email Format'),
  }),
  mapPropsToValues: () => ({
    email: '',
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(ForgotPassword);
