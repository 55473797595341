import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { storeSubscriptionRequested } from "store/subscription/subscriptionSlice";

export function useSubscription() {
  const dispatch = useDispatch();

  const onSubscription = useCallback(
    async (values: any, actions: any) => {
      actions.setSubmitting(true);
      await dispatch(
       storeSubscriptionRequested({
        email :values.email,
        })
      );
    },
    [dispatch]
  );

  return { onSubscription };
}
