import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  deleteUserAddressRequested,
  loadUserRequested,
  storeUserAddressRequested,
  updateUserAddressRequested,
  updateUserPasswordRequested,
  updateUserRequested,
  uploadProfileImageRequested,
} from 'store/user/userSlice';

//load user info
export function useLoadUser() {
  const { auth } = useSelector((state: RootState) => state.auth);
  const { updateProfileImage } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const onLoadUser = useCallback(async () => {
    if (auth) {
      await dispatch(
        loadUserRequested({
          id: auth?.user?.id,
        })
      );
    }
  }, [dispatch, auth?.user?.id]);

  useEffect(() => {
    onLoadUser();
  }, [onLoadUser, auth, updateProfileImage]);

  return { onLoadUser };
}

export function useUpdateUser() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onUpdateUser = useCallback(
    async (values: any, actions: any) => {
      await dispatch(
        updateUserRequested({
          id: auth?.user?.id,
          firstname: values.firstname,
          lastname: values.lastname,
          mobile: values.mobile,
          email: values.email,
        })
      );
    },
    [dispatch, auth]
  );

  return { onUpdateUser };
}

export function useStoreUserAddress() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onStoreUserAddress = useCallback(
    async (values: any, actions: any) => {
      await dispatch(
        storeUserAddressRequested({
          userId: auth?.user?.id,
          postalcode: values.postalcode,
          streetAndHouseNo: values.streetAndHouseNo,
          locality: values.locality,
          isDefault: values.isDefault,
        })
      );
    },
    [dispatch, auth]
  );

  return { onStoreUserAddress };
}
export function useUpdateUserAddress() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onUpdateUserAddress = useCallback(
    async (values: any, actions: any) => {
      await dispatch(
        updateUserAddressRequested({
          id: values.id,
          userId: auth?.user?.id,
          postalcode: values.postalcode,
          streetAndHouseNo: values.streetAndHouseNo,
          locality: values.locality,
          isDefault: values.isDefault,
        })
      );
    },
    [dispatch, auth]
  );

  return { onUpdateUserAddress };
}

export function useDeleteUserAddress() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onDeleteUserAddress = useCallback(
    async (values: any) => {
      await dispatch(
        deleteUserAddressRequested({
          id: values.id,
          userId: auth?.user?.id,
        })
      );
    },
    [dispatch]
  );

  return { onDeleteUserAddress };
}

export function useUploadImage() {
  const dispatch = useDispatch();
  const onUploadImage = useCallback(
    async (values: any) => {
      await dispatch(uploadProfileImageRequested(values));
    },
    [dispatch]
  );

  return { onUploadImage };
}

export function useUpdateUserPassword() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onUpdateUserPassword = useCallback(
    async (values: any, actions: any) => {
      await dispatch(
        updateUserPasswordRequested({
          userId: auth?.user?.id,
          currentPwd: values.currentPwd,
          newPwd: values.newPwd,
        })
      );
    },
    [dispatch]
  );

  return { onUpdateUserPassword };
}
