import { put, call, takeEvery, select } from 'redux-saga/effects';
import {
  storeCartRequested,
  storeCartSuccess,
  storeCartFail,
  updateCartRequested,
  updateCartSuccess,
  updateCartFail,
  deleteCartRequested,
  deleteCartSuccess,
  deleteCartFail,
} from './cartSlice';

export function* storeCartEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const state = yield select((state: any) => state.cart);
    const existingItem = state.cart.some(
      (cartItem: any) =>
        cartItem?.productId === action.payload?.productId &&
        action.payload?.isCombo === false
    );

    if (existingItem) {
      const updatedStoreCart = state.cart.map((cartItem: any) =>
        cartItem?.productId === action?.payload?.productId
          ? { ...cartItem, qty: cartItem?.qty + 1 }
          : cartItem
      );
      yield put(storeCartSuccess(updatedStoreCart));
    } else {
      yield put(
        storeCartSuccess([
          ...(state.cart || []),
          {
            ...action.payload,
            qty: 1,
          },
        ])
      );
    }
  } catch (error: any) {
    yield put(storeCartFail(error.message));
  }
}

export function* updateCartEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const updatedCart = [];
    const quantityChange = action.payload?.type === 'reduce' ? -1 : 1;
    const state = yield select((state: any) => state.cart);

    for (const cartItem of state.cart) {
      if (state.cart.indexOf(cartItem) === action.payload?.id) {
        if (cartItem?.qty === 1 && action.payload?.type === 'reduce') {
          yield call(deleteCartEffect, {
            payload: {},
            type: '',
          });
          continue;
        }
        updatedCart.push({
          ...cartItem,
          qty: cartItem.qty + quantityChange,
        });
      } else {
        updatedCart.push(cartItem);
      }
    }
    yield put(updateCartSuccess(updatedCart));
  } catch (error: any) {
    yield put(updateCartFail(error.message));
  }
}

export function* deleteCartEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    let updatedCart = [];
    const state = yield select((state: any) => state.cart);
    updatedCart = state?.cart?.filter(
      (cartItem: any) => state?.cart?.indexOf(cartItem) !== action.payload
    );
    yield put(deleteCartSuccess(updatedCart));
  } catch (error: any) {
    yield put(deleteCartFail(error.message));
  }
}

export function* cartSaga(): Generator<any, void, any> {
  yield takeEvery(storeCartRequested, storeCartEffect);
  yield takeEvery(deleteCartRequested, deleteCartEffect);
  yield takeEvery(updateCartRequested, updateCartEffect);
}
