import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useGoogleSignin } from 'hooks/GoogleLogin';

const clientId =
  '89624877053-rlec3f4dqnubtq7kvoab3i4ib6soqgvd.apps.googleusercontent.com';

const App = () => {
  const { onGoogleSignin } = useGoogleSignin();
  const handleLoginSuccess = (credentialResponse: any) => {
    const { credential } = credentialResponse;
    onGoogleSignin({ credential }, { setSubmitting: (status: boolean) => {} });
  };

  const handleLoginFailure = (error: void) => {
    console.error('Login Failed:', error);
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className='App'>
        <div>
          <GoogleLogin
            text='continue_with'
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
          />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
