import storage from 'redux-persist/lib/storage';

export const persistConfig = {
  key: 'root',
  storage,
};

export const cartConfig = {
  key: 'cart',
  storage,
};

export const orderConfig = {
  key: 'orderType',
  storage,
  version: 1,
  whitelist: ['orderType'],
};

export const couponConfig = {
  key: 'coupon',
  storage,
  version: 1,
  whitelist: ['coupon'],
};

export const themeConfig = {
  key: 'theme',
  storage,
};

export const guestConfig = {
  key: 'guest',
  storage,
  version: 1,
  whitelist: ['guest', 'address'],
};
