import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { isAuthorized as useAuthorized } from 'store/auth/selector';
import * as routes from 'constants/routes';
import { AiOutlineUser } from 'react-icons/ai';
import { PiSignOutBold } from 'react-icons/pi';
import { CgProfile } from 'react-icons/cg';
import { ImageURL } from 'config';

interface IProfileMenu {
  setOpen: any;
  setOpenUser: any;
  setOpenUserQuery: any;
  setOpenAuthQuery: any;
}

export const ProfileMenu: React.FC<IProfileMenu> = ({
  setOpen,
  setOpenUser,
  setOpenUserQuery,
  setOpenAuthQuery,
}) => {
  const navigate = useNavigate();
  const isAuthorized = useSelector(useAuthorized);
  const { userInfo } = useSelector((state: RootState) => state.user);

  const handleSignOut = () => {
    if (isAuthorized) {
      localStorage.removeItem('persist:root');
      localStorage.removeItem('persist:guest');
      localStorage.removeItem('persist:coupon');
      window.location.reload();
    } else {
      setOpenUser(true);
      setOpenUserQuery('user');
      setOpenAuthQuery('login');
    }
  };
  const userName =
    userInfo?.firstName && userInfo?.lastName
      ? `${userInfo?.firstName} ${userInfo?.lastName}`
      : 'Guest';
  return (
    <div className='flex flex-col border border-pureWhite/20 backdrop-blur-xl text-textGray rounded-md bg-pureBlack/90 min-w-[14rem]'>
      {userInfo && Object.keys(userInfo)?.length > 0 && (
        <div className='flex items-center gap-3 rounded-t-md bg-cardGray/30 py-2 px-4 border-b-2 border-b-pureWhite/10'>
          {userInfo?.avatar ? (
            <img
              src={
                userInfo?.avatar?.startsWith('http')
                  ? userInfo?.avatar
                  : `${ImageURL}/${userInfo?.avatar}`
              }
              alt='email'
              className='w-6 md:w-8 h-6 md:h-8 rounded-full cursor-pointer'
            />
          ) : (
            <CgProfile className='h-6 w-6 cursor-pointer' />
          )}
          <div className='text-textGray pr-8'>
            <div className='text-p2'>{userName}</div>
            <div className='text-p3'> {userInfo && userInfo?.email} </div>
          </div>
        </div>
      )}

      <div className='text-p2'>
        {isAuthorized && (
          <div
            className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'
            onClick={() => [navigate(routes.PROFILE), setOpen(false)]}
          >
            <div className='flex gap-2 items-center '>
              <AiOutlineUser className='w-5 h-5 text-textGray' />

              <div>Profile</div>
            </div>
          </div>
        )}
        <div
          className='px-4 py-2 hover:bg-pureWhite/20 cursor-pointer'
          onClick={handleSignOut}
        >
          <div className='flex gap-2 items-center'>
            <PiSignOutBold className='w-5 h-5' />
            <div>{isAuthorized ? 'Sign Out' : 'Sign In'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
