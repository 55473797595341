import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  storeOrderRequested,
  updateOrderTypeRequested,
  loadOrdersRequested,
  loadOrderTimeRequested,
  loadOrderRequested,
  updateOrderStatusRequested,
} from 'store/order/orderSlice';
import { isAuthorized as useAuthorized } from 'store/auth/selector';
import {
  CalculateDiscountedPrice,
  CalculatePrice,
  ItemDescription,
} from 'utils/cart';
import { storeReviewRequested } from 'store/review/reviewSlice';
import { useNavigate } from 'react-router-dom';

export function useLoadOrderTime() {
  const dispatch = useDispatch();

  const onLoadOrderTime = useCallback(async () => {
    await dispatch(loadOrderTimeRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadOrderTime();
  }, [onLoadOrderTime]);
  return { onLoadOrderTime };
}

export function useStoreOrder() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isAuthorized = useSelector(useAuthorized);
  const { auth } = useSelector((state: RootState) => state.auth);
  const { orderType } = useSelector((state: RootState) => state.order);
  const { guest, address } = useSelector((state: RootState) => state.guest);
  const { cart } = useSelector((state: RootState) => state.cart);
  const { coupon } = useSelector((state: RootState) => state.coupon);

  const onStoreOrder = useCallback(
    async (values: any) => {
      await dispatch(
        storeOrderRequested({
          values: {
            orderRequest: {
              userId: isAuthorized ? auth?.user?.id : 0,
              firstname: isAuthorized ? '' : guest?.firstName,
              lastname: isAuthorized ? '' : guest?.lastName,
              email: isAuthorized ? '' : guest?.email,
              mobile: isAuthorized ? '' : guest?.mobile,
              changedMobileData: values?.mobile,
              orderItems: cart?.map((item: any) => ({
                description: ItemDescription(item),
                itemId: item?.productId,
                qty: item?.qty,
                price: parseFloat(CalculatePrice(item, orderType?.type)),
                totalDiscount: item?.totalDiscount,
              })),
              address: {
                id: isAuthorized ? values?.address?.id : 0,
                postalcode: address?.postalcode ? address?.postalcode : '',
                streetAndHouseNo: address?.streetAndHouseNo
                  ? address?.streetAndHouseNo
                  : '',
                locality: address?.locality ? address?.locality : '',
              },
              deliveryTime: values?.time?.value,
              deliveryOption: orderType?.type === 'delivery' ? 0 : 1,
              specialNote: values?.specialNote,
              paymentType: values?.paymentType,
              userType: isAuthorized ? 1 : 2,
              coupon: coupon?.percentage ? coupon?.percentage : null,
              couponId: coupon?.id ? coupon?.id : null,
            },
          },
          isAuthorized: isAuthorized,
          navigate: navigation,
        })
      );
    },
    [dispatch, guest, address, isAuthorized, auth]
  );

  return { onStoreOrder };
}

export function useLoadOrders() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onLoadOrders = useCallback(async () => {
    await dispatch(
      loadOrdersRequested({
        userId: auth?.user?.id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    onLoadOrders();
  }, [onLoadOrders]);

  return { onLoadOrders };
}

export function useLoadOrder(id: string) {
  const dispatch = useDispatch();

  const onLoadOrder = useCallback(async () => {
    await dispatch(loadOrderRequested({ id: id }));
  }, [dispatch]);

  useEffect(() => {
    onLoadOrder();
  }, [onLoadOrder]);
  return { onLoadOrder };
}

export function useUpdateOrderStatus(refNo: any) {
  const dispatch = useDispatch();

  const onUpdateOrderStatus = useCallback(async () => {
    await dispatch(
      updateOrderStatusRequested({
        referenceNo: refNo,
        isStatus: true,
      })
    );
  }, [dispatch]);
  useEffect(() => {
    onUpdateOrderStatus();
  }, [onUpdateOrderStatus]);
  return { onUpdateOrderStatus };
}

export function useUpdateOrderType() {
  const dispatch = useDispatch();
  const onUpdateOrderType = useCallback(
    async (values: any) => {
      await dispatch(updateOrderTypeRequested(values));
    },
    [dispatch]
  );

  return { onUpdateOrderType };
}

export function useStoreWriteReview() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: RootState) => state.auth);
  const onStoreWriteReview = useCallback(
    async (values: any, actions: any) => {
      await dispatch(
        storeReviewRequested({
          userId: auth?.user?.id,
          comment: values.comment,
          status: 0,
          email: auth?.user?.username,
          isActive: true,
        })
      );
    },
    [dispatch, auth?.user]
  );

  return { onStoreWriteReview };
}
