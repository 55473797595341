import { useMemo } from "react";

interface IRadioProps {
  name?: any;
  type?: any;
  onChange?: any;
  values?: any;
  onBlur?: any;
  placeholder?: string;
  onKeyDown?: any;
  defaultValue?: any;
  touches?: any;
  errors?: any;
  disabled?: any;
  className?: string;
  hidden?: string;
  label?: string;
  checked?: any;
}

export function Radio(props: IRadioProps) {
  const isInvalid = useMemo(() => {
    return props?.touches?.[props?.name] && props?.errors?.[props?.name];
  }, [props?.touches, props?.errors, props?.name]);

  return (
    <div className="flex justify-start items-center gap-2">
      <input
        id={props?.name}
        disabled={props?.disabled}
        type="radio"
        className={
          props?.className ? props?.className : "cursor-pointer w-4 h-4"
        }
        checked={props.checked}
        onChange={props?.onChange}
      />
      <label
        htmlFor={props.name}
        className={`text-p2 font-poppinsMedium text-pureWhite cursor-pointer ${
          props?.hidden && "hidden"
        }`}
      >
        {props.label}
      </label>
      {isInvalid ? (
        <p className="text-red-500 font-[600] text-[10px]">
          {props?.errors[props?.name]}
        </p>
      ) : null}
    </div>
  );
}
