import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import 'styles/menu.css';

export interface ITooltipProps {
  text?: string;
  children?: React.ReactNode;
}
export const Tooltip: React.FC<ITooltipProps> = ({ text, children }) => {
  const [visible, setVisible] = useState(false);
  const { theme } = useSelector((state: RootState) => state.theme);

  return (
    <div
      className='relative flex items-center cursor-pointer'
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div
          className={`z-50 absolute tooltip cursor-pointer bottom-full max-w-[10rem] p-1 border ${
            theme === 'dark'
              ? 'bg-gray-950 text-gray-100 border-white/30'
              : 'bg-gray-100 text-gray-950'
          }  text-[10px]  rounded-sm tooltip`}
        >
          {text}
        </div>
      )}
    </div>
  );
};
