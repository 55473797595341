import { put, takeEvery, call } from 'redux-saga/effects';

import {
  storeReviewRequested,
  storeReviewSuccess,
  storeReviewFail,
  loadAcceptedReviewsFail,
  loadAcceptedReviewsRequested,
  loadAcceptedReviewsSuccess,
} from './reviewSlice';
import { apiURL, axiosInstance } from 'config';
import toast from 'react-hot-toast';

export function* storeReviewEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${apiURL}/create-review`,
      action.payload
    );
    if (data?.succeeded) {
      yield put(storeReviewSuccess(data));
      toast.success(
        'Review added successfully Thank you For Your Valuable Time'
      );
    } else {
      toast.error(data?.errors[0]);
    }
  } catch (error: any) {
    yield put(storeReviewFail(error.message));
  }
}

export function* loadAcceptedReviewsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-review-by-accepted`
    );
    yield put(loadAcceptedReviewsSuccess(data));
  } catch (error: any) {
    yield put(loadAcceptedReviewsFail(error.message));
  }
}

export function* reviewSaga(): Generator<any, void, any> {
  yield takeEvery(storeReviewRequested, storeReviewEffect);
  yield takeEvery(loadAcceptedReviewsRequested, loadAcceptedReviewsEffect);
}
