import { put, takeEvery } from 'redux-saga/effects';
import {
  updateThemeRequested,
  updateThemeSuccess,
  updateThemeFail,
} from './themeSlice';

export function* updateThemeEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const data = action.payload;
    yield put(updateThemeSuccess(data));
  } catch (error: any) {
    yield put(updateThemeFail(error.message));
  }
}

export function* themeSaga(): Generator<any, void, any> {
  yield takeEvery(updateThemeRequested, updateThemeEffect);
}
