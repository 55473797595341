import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  userInfo: {},
  storeUserAddress: null,
  storeAddressLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUserRequested(state, action: PayloadAction<{ id: any }>) {
      state.userLoading = true;
      state.error = null;
    },
    loadUserSuccess(state, action: PayloadAction<any>) {
      state.userLoading = false;
      state.error = null;
      state.userInfo = action?.payload?.result;
    },
    loadUserFail(state, action: PayloadAction<any>) {
      state.userLoading = false;
      state.error = action.payload;
    },

    storeUserAddressRequested(
      state,
      action: PayloadAction<{
        userId: any;
        postalcode: string;
        streetAndHouseNo: string;
        locality: string;
        isDefault: boolean;
      }>
    ) {
      state.storeAddressLoading = true;
      state.error = null;
    },
    storeUserAddressSuccess(state, action: PayloadAction<any>) {
      state.storeAddressLoading = false;
      state.error = null;
      state.storeUserAddress = action.payload;
    },
    storeUserAddressFail(state, action: PayloadAction<any>) {
      state.storeAddressLoading = false;
      state.error = action.payload;
    },
    updateUserAddressRequested(
      state,
      action: PayloadAction<{
        id: any;
        userId: any;
        postalcode: string;
        streetAndHouseNo: string;
        locality: string;
        isDefault: any;
      }>
    ) {
      state.storeAddressLoading = true;
      state.error = null;
    },
    updateUserAddressSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateUserAddress = action.payload?.result;
    },
    updateUserAddressFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateUserRequested(
      state,
      action: PayloadAction<{
        id: any;
        firstname: string;
        lastname: string;
        mobile: string;
        email: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateUserSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateUser = action.payload;
    },
    updateUserFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteUserAddressRequested(
      state,
      action: PayloadAction<{
        id: any;
        userId: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    deleteUserAddressSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.deleteUserAddress = action.payload;
    },
    deleteUserAddressFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    uploadProfileImageRequested(
      state,
      action: PayloadAction<{
        userId: any;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    uploadProfileImageSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.updateProfileImage = action.payload;
    },
    uploadProfileImageFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateUserPasswordRequested(
      state,
      action: PayloadAction<{ userId: any; currentPwd: any; newPwd: any }>
    ) {
      state.loading = true;
      state.error = null;
    },
    updateUserPasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.userNewPassword = action?.payload;
    },
    updateUserPasswordFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  updateUserPasswordRequested,
  updateUserPasswordSuccess,
  updateUserPasswordFail,
  loadUserRequested,
  loadUserSuccess,
  loadUserFail,
  storeUserAddressRequested,
  storeUserAddressSuccess,
  storeUserAddressFail,
  updateUserAddressRequested,
  updateUserAddressSuccess,
  updateUserAddressFail,
  updateUserRequested,
  updateUserSuccess,
  updateUserFail,
  uploadProfileImageRequested,
  uploadProfileImageSuccess,
  uploadProfileImageFail,
  deleteUserAddressRequested,
  deleteUserAddressSuccess,
  deleteUserAddressFail,
} = userSlice.actions;

export default userSlice.reducer;
