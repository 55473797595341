import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from 'assets/images/Logo.svg';
import BucketDark from 'assets/images/header/bucket.svg';
import BucketLight from 'assets/images/header/bucket-light.svg';
import ThemeDark from 'assets/images/header/theme.svg';
import ThemeLight from 'assets/images/header/theme-light.svg';
import MenuLight from 'assets/images/header/menu-light.svg';
import MenuDark from 'assets/images/header/menu-dark.svg';
import { Menu, NavBar, ProfileMenu } from 'templates/Header';
import { Modal } from 'components/shared';
import { useLoadUser } from 'hooks';
import { RootState } from 'store';
import { isAuthorized as useAuthorized } from 'store/auth/selector';
import * as routes from 'constants/routes';
import { ImageURL } from 'config';
import { useUpdateTheme } from 'hooks/Theme';
import User from 'templates/Orders/User';
import { CgProfile } from 'react-icons/cg';
import { FiPhoneCall } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { setMenuOpen } from 'store/theme/themeSlice';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onLoadUser } = useLoadUser();

  const { theme, menuOpen } = useSelector((state: RootState) => state.theme);
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [openUserQuery, setOpenUserQuery] = useState<string>('');
  const [openAuthQuery, setOpenAuthQuery] = useState<string>('');
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const HandleMenuOpen = () => {
    dispatch(setMenuOpen(!menuOpen as any));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target as Node)
      ) {
        setProfileMenuOpen(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        dispatch(setMenuOpen(false as any));
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const { onUpdateTheme } = useUpdateTheme();

  const changeTheme = () => {
    if (theme === 'dark') {
      onUpdateTheme('light');
    } else {
      onUpdateTheme('dark');
    }
  };

  const isAuthorized = useSelector(useAuthorized);
  const { auth } = useSelector((state: RootState) => state.auth);
  const { cart } = useSelector((state: RootState) => state.cart);
  const { orderType } = useSelector((state: RootState) => state.order);
  const { guest, address } = useSelector((state: RootState) => state.guest);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { hero } = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    setOpenUser(false);
    setOpenUserQuery('');
    setOpenAuthQuery('');
  }, [auth, guest, isAuthorized]);

  useLayoutEffect(() => {
    onLoadUser();
  }, [isAuthorized]);

  return (
    <div>
      <div className='h-[3.5rem] w-full bg-headerBg/5 backdrop-blur-3xl border-b border-b-pureWhite/10 drop-shadow-xl drop-shadow-pureBlack flex justify-between items-center px-4 md:px-8 z-50'>
        <img
          onClick={() => [navigate(routes.HOME)]}
          src={logo}
          alt='logo'
          className='w-[7rem] lg:w-[8rem] cursor-pointer'
        />

        <div className='flex items-center gap-4 text-textGray text-p2'>
          <div className='flex items-center gap-4'>
            <div className='hidden md:block'>
              <NavBar />
            </div>

            <img
              src={theme === 'dark' ? ThemeDark : ThemeLight}
              alt='theme'
              className='w-5 cursor-pointer hidden md:block'
              onClick={() => changeTheme()}
            />

            <div className='md:hidden'>
              <a href={`tel:${hero?.phoneNo}`}>
                <FiPhoneCall className='w-5 h-5 text-textGray' />
              </a>
            </div>

            <div
              className='relative'
              onClick={() => navigate(routes.ORDER_SUMMARY)}
            >
              <img
                src={theme === 'dark' ? BucketDark : BucketLight}
                alt='bucket'
                className='w-5 cursor-pointer z-20'
              />
              {cart?.length > 0 && (
                <div className='absolute z-10 -top-2 -right-2 w-4 h-4 rounded-full bg-red-600 flex justify-center items-center text-p3 text-white'>
                  {cart?.length}
                </div>
              )}
            </div>
            <div className='h-6 w-[1.5px] bg-textGray/80 rounded-full hidden md:block'></div>

            <div
              className='relative hidden md:block'
              ref={profileMenuRef}
            >
              {userInfo?.avatar ? (
                <img
                  src={
                    userInfo?.avatar?.startsWith('http')
                      ? userInfo?.avatar
                      : `${ImageURL}/${userInfo?.avatar}?v=${userInfo?.updatedAt}`
                  }
                  alt='email'
                  className='w-6 md:w-8 h-6 md:h-8 rounded-full cursor-pointer'
                  onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                />
              ) : (
                <CgProfile
                  className='h-6 w-6 cursor-pointer'
                  onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                />
              )}

              {profileMenuOpen ? (
                <div className='absolute top-10 right-0 z-50'>
                  <ProfileMenu
                    setOpen={setProfileMenuOpen}
                    setOpenUser={setOpenUser}
                    setOpenUserQuery={setOpenUserQuery}
                    setOpenAuthQuery={setOpenAuthQuery}
                  />
                </div>
              ) : null}
            </div>
            <div
              className='relative block md:hidden'
              ref={menuRef}
            >
              <img
                src={theme === 'dark' ? MenuDark : MenuLight}
                alt='menu'
                className='w-4 cursor-pointer'
                onClick={HandleMenuOpen}
              />
              {menuOpen && (
                <Menu
                  data={auth}
                  setOpen={setMenuOpen}
                  setOpenUser={setOpenUser}
                  setOpenUserQuery={setOpenUserQuery}
                  setOpenAuthQuery={setOpenAuthQuery}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={openUser}
        setISOpen={setOpenUser}
      >
        <User
          orderType={orderType?.type}
          openUserQuery={openUserQuery}
          setOpenUserQuery={setOpenUserQuery}
          openAuthQuery={openAuthQuery}
          setOpenAuthQuery={setOpenAuthQuery}
          address={address}
          guest={guest}
        />
      </Modal>
    </div>
  );
};
