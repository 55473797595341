import { ImageURL } from 'config';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  loadCategoriesRequested,
  loadProductsRequested,
} from 'store/products/productSlice';

export function useProducts(products: any, orderType: any) {
  const generateUUID = () =>
    Date?.now()?.toString(36) + Math?.random()?.toString(36)?.substring(2, 15);

  const modifiedProductsWithoutAddons = products?.map((item: any) => ({
    subCategoryId: item?.subCategoryId,
    subCategoryName: item?.name,
    subCategoryDescription: item?.description,
    products: item?.items?.map((p: any) => ({
      productId: p?.id,
      productName: p?.name,
      isActive: p?.isActive,
      productDescription: p?.description,
      cPrice: p?.cPrice,
      dPrice: p?.dPrice,
      totalPrice: orderType?.type === 'collection' ? p?.cPrice : p?.dPrice,
      totalDiscount: p?.totalDiscount,
      discountPrice: (p?.totalDiscount / 100) * p?.totalPrice,
      discountedPrice: p?.totalPrice - p?.discountPrice,
      offers: p?.offers,
      image: p?.image ? `${ImageURL}/${p?.image}` : null,
      updateAt:p?.updateAt,
      isCombo:
        p?.addons?.length > 0 ||
        p?.combo?.some(
          (pc: any) => pc?.selectOptionType === 0 && pc?.selectionCount > 0
        ) ||
        p?.combo?.some(
          (pc: any) => pc?.selectOptionType === 0 && pc?.variance?.length > 0
        ) ||
        p?.combo?.some(
          (pc: any) => pc?.selectOptionType === 1 && pc?.selectionCount > 0
        ),

      combo: [...p?.combo]?.map((e) => ({
        comboId: e?.id,
        comboName: e?.title,
        type: e?.selectOptionType,
        count: e?.selectionCount,
        comboItems: e?.description?.split(', ').map((c: any) => ({
          id: generateUUID(),
          name: c,
          selected: false,
          varients: e?.variance
            ?.filter((v: any) => v?.name === c)
            ?.flatMap((vi: any) =>
              vi?.description?.split(', ').map((vd: any) => ({
                varientItemId: generateUUID(),
                varientItemName: vd,
                selected: false,
              }))
            ),
        })),
      })),
      addons: p?.addons?.map((pa: any) => ({
        id: pa?.id,
        name: pa?.description,
        selected: false,
        type: 2,
        price: pa?.price,
      })),
    })),
  }));

  const modifiedProducts = modifiedProductsWithoutAddons?.map((e: any) => ({
    ...e,
    products: e?.products?.map((ep: any) => ({
      ...ep,
      combo: [...ep?.combo, ...ep?.addons],
    })),
  }));

  return { modifiedProducts };
}

export function useLoadCategory() {
  const dispatch = useDispatch();

  const onLoadCategory = useCallback(async () => {
    await dispatch(loadCategoriesRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadCategory();
  }, [onLoadCategory]);

  return { onLoadCategory };
}

export function useLoadProduct(selectedCategory: any) {
  const dispatch = useDispatch();

  const onLoadProduct = useCallback(async () => {
    if (selectedCategory) {
      await dispatch(loadProductsRequested(selectedCategory));
    }
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    onLoadProduct();
  }, [onLoadProduct, selectedCategory]);

  return { onLoadProduct };
}
