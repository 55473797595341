// select option
export const CalculateSelectionPrice: any = (
  combo: any,
  orderType: any,
  product: any
) => {
  let addonsTotal: number = 0;
  let productDiscount: number = 0;
  const addons = combo?.filter(
    (item: any) => item?.type === 2 && item?.selected
  );
  addons?.length > 0 &&
    (addonsTotal = addons?.reduce((addonsTotal: any, item: any) => {
      return addonsTotal + item?.price;
    }, 0));

  let productPrice: number =
    orderType === 'delivery' ? product?.dPrice : product?.cPrice;

  productDiscount =
    product?.totalDiscount !== 0
      ? productPrice * (product?.totalDiscount / 100)
      : 0;

  return productPrice - productDiscount + addonsTotal;
};

// order hook
export const CalculatePrice: any = (data: any, orderType: any) => {
  let addonsTotal: number = 0;
  const addons = data?.combo?.filter(
    (item: any) => item?.type === 2 && item?.selected
  );
  addons?.length > 0 &&
    (addonsTotal = addons?.reduce((addonsTotal: any, item: any) => {
      return addonsTotal + item?.price;
    }, 0));
  let productPrice: number =
    orderType === 'collection' ? data?.cPrice : data?.dPrice;

  return productPrice + addonsTotal;
};

// menu cart,
export const CalculateDiscountedPrice: any = (data: any, orderType: any) => {
  let addonsTotal: number = 0;
  let productDiscount: number = 0;
  const addons = data?.combo?.filter(
    (item: any) => item?.type === 2 && item?.selected
  );
  addons?.length > 0 &&
    (addonsTotal = addons?.reduce((addonsTotal: any, item: any) => {
      return addonsTotal + item?.price;
    }, 0));
  let productPrice: number =
    orderType === 'collection' ? data?.cPrice : data?.dPrice;
  data?.totalDiscount > 0 &&
    (productDiscount = productPrice * (data?.totalDiscount / 100));
  return productPrice - productDiscount + addonsTotal;
};

// menu checkout
export const CalculateDiscountedTotal = (data: any, orderType: any) => {
  let total: number = data?.reduce((total: any, product: any) => {
    let addonsTotal: number = 0;
    let productDiscount: number = 0;
    let price: number = 0;

    const addons = product?.combo?.filter(
      (item: any) => item?.type === 2 && item?.selected
    );

    addons?.length > 0 &&
      (addonsTotal = addons?.reduce((addonsTotal: any, item: any) => {
        return addonsTotal + item?.price;
      }, 0));

    let productPrice: number =
      orderType === 'collection' ? product?.cPrice : product?.dPrice;

    productDiscount =
      product?.totalDiscount !== 0
        ? productPrice * (product?.totalDiscount / 100)
        : 0;

    price = productPrice - productDiscount + addonsTotal;
    return total + price * product?.qty;
  }, 0);

  return total;
};

//order summery
export const CalculateSubTotal = (data: any, orderType: any) => {
  let total: number = data?.reduce((total: any, product: any) => {
    let addonsTotal: number = 0;
    let price: number = 0;

    const addons = product?.combo?.filter(
      (item: any) => item?.type === 2 && item?.selected
    );

    addons?.length > 0 &&
      (addonsTotal = addons?.reduce((addonsTotal: any, item: any) => {
        return addonsTotal + item?.price;
      }, 0));
    let productPrice: number =
      orderType === 'collection' ? product?.cPrice : product?.dPrice;

    price = productPrice + addonsTotal;
    return total + price * product?.qty;
  }, 0);

  return total;
};

export const calculateDiscount = (data: any, orderType: any) => {
  let discount: number = 0;
  discount = data?.reduce((discount: any, item: any) => {
    let price: number =
      orderType === 'collection' ? item?.cPrice : item?.dPrice;
    return discount + price * item?.qty * (item?.totalDiscount / 100);
  }, 0);

  return discount;
};

export const calculateCouponDiscount = (coupon: any, total: number) => {
  let discount: number = 0;
  discount = coupon?.percentage ? (coupon?.percentage / 100) * total : 0;
  return discount;
};

////
// isAddons
export const hasAddonItems = (value: any) => {
  const addonItems = value?.combo?.filter(
    (item: any) => item?.type === 2 && item?.selected
  );
  return addonItems?.length > 0;
};

export const defaultItemsDescription = (value: any) => {
  const defaultItems = value?.combo?.filter(
    (item: any) => item?.selectOptionType !== 2 && item?.selectionCount === 0
  );
  const description = defaultItems
    .flatMap((item: any) => item.description.map((desc: any) => desc.name))
    .join(', ');
  return description;
};

// default, combo
export const selectedItemsDescription = (value: any) => {
  const defaultDescription = value?.combo
    ?.filter((item: any) => item?.type !== 2 && item?.count === 0)
    ?.flatMap((i: any) =>
      i.comboItems.map((desc: any) => {
        const selectedVarients = desc?.varients
          ?.filter((variant: any) => variant?.selected)
          ?.map((variant: any) => variant?.varientItemName);
        if (selectedVarients?.length) {
          return `${desc?.name} (${selectedVarients.join(', ')})`;
        }
        return desc?.name;
      })
    )
    ?.filter(Boolean)
    ?.join(', ');

  const comboDescription = value?.combo
    ?.filter((item: any) => item?.type !== 2 && item?.count !== 0)
    ?.flatMap((i: any) =>
      i?.comboItems
        ?.filter((i: any) => i?.selected)
        ?.map((desc: any) => {
          const selectedVarients = desc?.varients
            ?.filter((variant: any) => variant?.selected)
            ?.map((variant: any) => variant?.varientItemName);
          if (selectedVarients?.length) {
            return `${desc?.name} (${selectedVarients.join(', ')})`;
          }
          return desc?.name;
        })
    )
    ?.filter(Boolean)
    ?.join(', ');

  const description = [defaultDescription, comboDescription]
    .filter(Boolean)
    .join(', ');

  return description;
};

// addons
export const addonsItemsDescription = (value: any) => {
  const addonsDescription = value?.combo
    ?.filter((item: any) => item?.type === 2 && item?.selected)
    ?.map((decs: any) => decs?.name)
    .filter(Boolean)
    .join(', ');

  return addonsDescription;
};

// default, combo, addons
export const ItemDescription = (value: any) => {
  const defaultDescription = value?.combo
    ?.filter((item: any) => item?.type !== 2 && item?.count === 0)
    ?.flatMap((i: any) =>
      i?.comboItems.map((desc: any) => {
        const selectedVarients = desc?.varients
          ?.filter((variant: any) => variant?.selected)
          ?.map((variant: any) => variant?.varientItemName);
        if (selectedVarients?.length) {
          return `${desc?.name} (${selectedVarients.join(', ')})`;
        }
        return desc?.name;
      })
    )
    ?.filter(Boolean)
    ?.join(', ');

  const comboDescription = value?.combo
    ?.filter((item: any) => item?.type !== 2 && item?.count !== 0)
    ?.flatMap((i: any) =>
      i?.comboItems
        ?.filter((i: any) => i?.selected)
        ?.map((desc: any) => {
          const selectedVarients = desc?.varients
            ?.filter((variant: any) => variant?.selected)
            ?.map((variant: any) => variant?.varientItemName);
          if (selectedVarients?.length) {
            return `${desc?.name} (${selectedVarients.join(', ')})`;
          }
          return desc?.name;
        })
    )
    ?.filter(Boolean)
    ?.join(', ');

  const addonsDescription = value?.combo
    ?.filter((item: any) => item?.type === 2 && item?.selected)
    ?.map((decs: any) => decs?.name)
    .filter(Boolean)
    .join(', ');

  const description = [defaultDescription, comboDescription, addonsDescription]
    .filter(Boolean)
    .join(', ');

  return description;
};
