import { Outlet, useLocation } from 'react-router-dom';
import { Header, Footer } from 'components/base';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import React from 'react';
import { SnowFall } from 'components/shared';
import { GoToTop } from 'hooks/GoToTop';
import moment from 'moment';
import { useLoadDashboardHeroSettings } from 'hooks';

export const ClientLayout: React.FC = () => {
  const location = useLocation();
  const { theme } = useSelector((state: RootState) => state.theme);
  const { hero, shopClosedLoading } = useSelector(
    (state: RootState) => state.dashboard
  );
  const { onLoadHeroSettings } = useLoadDashboardHeroSettings();

  const isShopClosed = (() => {
    if (!shopClosedLoading) {
      const currentTime = moment().tz('Europe/London').format('HH:mm:ss');

      return (
        hero?.isClose ||
        !(hero?.startTime < currentTime && currentTime < hero?.endTime)
      );
    }
  })();

  return (
    <React.Fragment>
      {hero?.isSnow && <SnowFall />}

      <div>
        {isShopClosed === true && !shopClosedLoading && (
          <div className='fixed top-0 left-0 flex justify-center items-center h-[2rem] w-full bg-red-700 backdrop-blur-3xl border-b border-b-pureWhite/10 drop-shadow-xl drop-shadow-pureBlack px-4 md:px-8 z-50'>
            <div className='font-poppins text-center md:text-p3 text-p4 text-white tracking-widest'>
              {hero?.resonNote
                ? hero?.resonNote
                : 'Shop is closed now!, Online Order Time is 12PM to 10:30PM'}
            </div>
          </div>
        )}
        <div
          className={`${theme} font-poppins tracking-wide text-textGray relative`}
        >
          <div
            className={`fixed ${
              isShopClosed === true ? 'top-[2rem]' : 'top-0'
            } left-0 w-full z-50`}
          >
            <Header />
          </div>
          <div
            className={`${theme === 'dark' ? 'bg-black' : 'bg-white'} ${
              isShopClosed === true ? 'pt-[2rem]' : 'pt-0'
            }`}
          >
            <Outlet />
          </div>
          {location.pathname !== '/menu' && (
            <div className=''>
              <Footer />
            </div>
          )}
        </div>
      </div>
      <GoToTop />
    </React.Fragment>
  );
};
