import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  cart: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    storeCartRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeCartSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cart = action.payload;
    },
    storeCartFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeCartReset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cart = [];
    },
    updateCartRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateCartSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cart = action.payload;
    },
    updateCartFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCartRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    deleteCartSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.cart = action.payload;
    },
    deleteCartFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  storeCartRequested,
  storeCartSuccess,
  storeCartFail,
  updateCartRequested,
  updateCartSuccess,
  updateCartFail,
  deleteCartRequested,
  deleteCartSuccess,
  deleteCartFail,
  storeCartReset,
} = cartSlice.actions;

export default cartSlice.reducer;
