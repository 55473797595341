import { put, takeEvery, call } from 'redux-saga/effects';
import { apiURL, axiosInstance } from 'config';

import {
  loadDashboardAdvertisementsFail,
  loadDashboardAdvertisementsRequested,
  loadDashboardAdvertisementsSuccess,
  loadDashboardHeroSettingsFail,
  loadDashboardHeroSettingsRequested,
  loadDashboardHeroSettingsSuccess,
  loadDashboardOffersFail,
  loadDashboardOffersRequested,
  loadDashboardOffersSuccess,
  loadLandingImagesRequested,
  loadLandingImagesSuccess,
  loadLandingImagesFail,
} from './dashboardSlice';

export function* loadHeroSettingsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-order-setting`
    );
    if (data?.succeeded) {
      yield put(loadDashboardHeroSettingsSuccess(data));
    }
  } catch (error: any) {
    yield put(loadDashboardHeroSettingsFail(error.message));
  }
}

export function* loadDashboardOffersEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-landing-offer-item`
    );
    yield put(loadDashboardOffersSuccess(data));
  } catch (error: any) {
    yield put(loadDashboardOffersFail(error.message));
  }
}

export function* loadAdvertisementsEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/index-advert?page=1&pageSize=15`
    );
    yield put(loadDashboardAdvertisementsSuccess(data));
  } catch (error: any) {
    yield put(loadDashboardAdvertisementsFail(error.message));
  }
}

export function* loadLandingImagesEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.get,
      `${apiURL}/get-landing-images`
    );
    yield put(loadLandingImagesSuccess(data));
  } catch (error: any) {
    yield put(loadLandingImagesFail(error.message));
  }
}

export function* dashboardSaga(): Generator<any, void, any> {
  yield takeEvery(loadDashboardHeroSettingsRequested, loadHeroSettingsEffect);
  yield takeEvery(
    loadDashboardAdvertisementsRequested,
    loadAdvertisementsEffect
  );
  yield takeEvery(loadDashboardOffersRequested, loadDashboardOffersEffect);
  yield takeEvery(loadLandingImagesRequested, loadLandingImagesEffect);

}
