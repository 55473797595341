import React from 'react';
import { Button, Input } from 'components/shared';
import Chicken from 'assets/images/login/Chicken.png';
import { withFormik, FormikProps } from 'formik';
import { string, object } from 'yup';
import GoogleLogin from '../GoogleLogin';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface ILoginProps {
  onSubmit?: any;
  setAuthOpenQuery?: any;
}

interface IFormValue {
  username: string;
  password: string;
}

function Login(props: FormikProps<IFormValue> & ILoginProps) {
  const { theme } = useSelector((state: RootState) => state.theme);
  const [showPassword, setShowPassword] = useState(false);

  const visiblePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className='h-fit w-full mx-auto px-4 pb-6 md:px-6 md:pb-10 lg:w-[50vw]'>
      <div
        className={`text-h3 font-poppinsSemiBold text-center mb-6 text-red-600`}
      >
        Log In to Crazy Fried Chicken!
      </div>
      <div className='flex flex-col md:flex-row items-center md:space-x-8'>
        <div className='w-full md:w-[50%] h-[50vw] md:h-[40vw] lg:h-[35vw] max-h-80 flex justify-center md:justify-end mb-6 md:mb-0'>
          <img
            src={Chicken}
            alt='login'
            className='w-full h-full object-cover'
          />
        </div>
        <div className='w-full md:w-[50%]'>
          <form
            onSubmit={props.handleSubmit}
            className='space-y-4'
          >
            <div className='space-y-4'>
              <Input
                name='username'
                label='Email'
                type='text'
                placeholder='Enter Email'
                values={props.values}
                onChange={props.handleChange}
                errors={props.errors}
                touches={props.touched}
              />
              <div className='relative flex flex-col gap-2'>
                <Input
                  name='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Enter Password'
                  values={props.values}
                  onChange={props.handleChange}
                  errors={props.errors}
                  touches={props.touched}
                />
                <div
                  className='absolute right-3 top-10 cursor-pointer'
                  onClick={visiblePassword}
                >
                  {showPassword ? (
                    <FaEye className='text-black' />
                  ) : (
                    <FaEyeSlash className='text-black' />
                  )}
                </div>
              </div>
              <div
                className={`text-p3 tracking-wider text-right cursor-pointer  ${
                  theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
                }`}
                onClick={() => {
                  setTimeout(() => props.setAuthOpenQuery('forgot'));
                }}
              >
                Forgot password?
              </div>

              <div className='flex justify-center'>
                <Button
                  title={props.isSubmitting ? 'Logging In...' : 'Login'}
                  type='submit'
                  variant='large'
                  disabled={props.isSubmitting}
                />
              </div>

              <div className='flex justify-end items-center mt-6 space-x-2'>
                <div
                  className={`text-p3 tracking-wider ${
                    theme === 'dark' ? 'text-gray-50' : 'text-gray-950'
                  }`}
                >
                  Don't have an account?
                </div>
                <div
                  className='text-p3 text-red-700 font-poppinsSemiBold tracking-wider cursor-pointer'
                  onClick={() => {
                    setTimeout(() => props.setAuthOpenQuery('signup'));
                  }}
                >
                  Sign Up
                </div>
              </div>
            </div>
          </form>
          <div className='mt-4'>
            <GoogleLogin />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withFormik<ILoginProps, IFormValue>({
  validationSchema: object().shape({
    username: string()
      .required('Email is required')
      .email('Invalid Email Format'),
    password: string().required('Password is required'),
  }),
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),
  handleSubmit: (values, { props, ...actions }) => {
    props.onSubmit(values, actions);
  },
})(Login);
