import { call, put, takeEvery } from 'redux-saga/effects';

import {
  storeCouponSuccess,
  storeCouponFail,
  storeCouponRequested,
} from './couponSlice';
import { apiURL, axiosInstance } from 'config';

export function* storeCouponEffect(action: {
  type: string;
  payload: any;
}): Generator<any, void, any> {
  try {
    const { data } = yield call(
      axiosInstance.post,
      `${apiURL}/check-coupon`,
      action.payload
    );

    if (data?.succeeded) {
      yield put(
        storeCouponSuccess({
          id: data?.result?.id,
          coupon: data?.result?.coupon,
          percentage: data?.result?.percentage,
        })
      );
    }

    if (data?.errors) {
      yield put(storeCouponFail(data?.errors?.[0]));
    }
  } catch (error: any) {
    yield put(storeCouponFail(error));
  }
}

export function* couponSaga(): Generator<any, void, any> {
  yield takeEvery(storeCouponRequested, storeCouponEffect);
}
