import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  orderTimeSlots: [],
  storeOrder: null,
  orders: [],
  order: {},
  orderType: {
    type: null,
    postalcode: null,
  },
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    loadOrderTimeRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadOrderTimeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.orderTimeSlots = action.payload.result;
    },
    loadOrderTimeFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    storeOrderRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeOrderSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeOrder = action.payload;
    },
    storeOrderFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    storeOrderReset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeOrder = {};
    },

    loadOrdersRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    loadOrdersSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.orders = action.payload?.result;
    },
    loadOrdersFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    loadOrderRequested(state, action: PayloadAction<{}>) {
      state.orderLoading = true;
      state.error = null;
    },
    loadOrderSuccess(state, action: PayloadAction<{ result: any }>) {
      state.orderLoading = false;
      state.error = null;
      state.order = action.payload?.result;
    },
    loadOrderFail(state, action: PayloadAction<{}>) {
      state.orderLoading = false;
      state.error = action.payload;
    },

    updateOrderStatusRequested(state, action: PayloadAction<{}>) {
      state.orderLoading = true;
      state.error = null;
    },
    updateOrderStatusSuccess(state, action: PayloadAction<{ result: any }>) {
      state.orderLoading = false;
      state.error = null;
      state.updateOrder = action.payload;
    },
    updateOrderStatusFail(state, action: PayloadAction<{}>) {
      state.orderLoading = false;
      state.error = action.payload;
    },

    updateOrderTypeRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateOrderTypeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.orderType = action.payload;
    },
    updateOrderTypeFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    storeOrderTypeReset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.orderType = {
        type: null,
        postalcode: null,
      };
    },
  },
});

export const {
  loadOrderTimeRequested,
  loadOrderTimeSuccess,
  loadOrderTimeFail,
  storeOrderRequested,
  storeOrderSuccess,
  storeOrderFail,
  storeOrderReset,
  loadOrdersRequested,
  loadOrdersSuccess,
  loadOrdersFail,
  loadOrderRequested,
  loadOrderFail,
  loadOrderSuccess,
  updateOrderStatusRequested,
  updateOrderStatusSuccess,
  updateOrderStatusFail,
  updateOrderTypeRequested,
  updateOrderTypeSuccess,
  updateOrderTypeFail,
  storeOrderTypeReset,
} = orderSlice.actions;

export default orderSlice.reducer;
