import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  coupon: {},
};

const guestSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    storeCouponRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    storeCouponSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.coupon = action.payload;
    },
    storeCouponFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.storeCouponFail = action.payload;
    },

    storeCouponReset(state, action: PayloadAction<any>) {
      state.loading = false;
      state.coupon = null;
    },
  },
});

export const {
  storeCouponRequested,
  storeCouponSuccess,
  storeCouponFail,
  storeCouponReset,
} = guestSlice.actions;

export default guestSlice.reducer;
