import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import * as routes from 'constants/routes';
import { Lazy, NotFound } from 'components/base';
import { ClientLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { isAuthorized as useAuthorized } from 'store/auth/selector';

export const ClientRouter = () => {
  const isAuthorized = useSelector(useAuthorized);

  const PrivateClientRoute = ({ children }: any) => {
    if (!isAuthorized) {
      return (
        <Navigate
          to={routes.HOME}
          replace
        />
      );
    }
    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateClientRoute>
              <ClientLayout />
            </PrivateClientRoute>
          }
        >
          <Route
            path={routes.PROFILE}
            element={<Lazy page='Profile' />}
          />
          <Route
            path={routes.ORDERS}
            element={<Lazy page='Orders' />}
          />
          <Route
            path={routes.ORDER_DETAIL}
            element={<Lazy page='OrderDetail' />}
          />
        </Route>
        <Route
          path='/'
          element={<ClientLayout />}
        >
          <Route
            index
            element={<Lazy page='Home' />}
          />
          <Route
            path={routes.MENU}
            element={<Lazy page='Menu' />}
          />
          <Route
            path={routes.ORDER_SUMMARY}
            element={<Lazy page='OrderSummary' />}
          />
          <Route
            path={routes.CHECKOUT}
            element={<Lazy page='Checkout' />}
          />
          <Route
            path={routes.ORDER_SUCCESS}
            element={<Lazy page='OrderSuccess' />}
          />
          <Route
            path={routes.ORDER_FAIL}
            element={<Lazy page='OrderFail' />}
          />
          <Route
            path={routes.RESTAURANT}
            element={<Lazy page='Restaurant' />}
          />
          <Route
            path={routes.TERMS}
            element={<Lazy page='Terms' />}
          />
          <Route
            path={routes.PRIVACY_POLICY}
            element={<Lazy page='PrivacyPolicy' />}
          />
          <Route
            path={routes.COOKIESPOLICY}
            element={<Lazy page='CookiesPolicy' />}
          />
          <Route
            path={routes.REVIEWS_FORM}
            element={<Lazy page='Review' />}
          />

          <Route
            path={routes.CONTACTUS}
            element={<Lazy page='ContactUs' />}
          />
        </Route>
        <Route
          path='*'
          element={<NotFound />}
        />
      </Routes>
    </BrowserRouter>
  );
};
