import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import moment from 'moment-timezone';

export function useMenu(cart: any, locationState: any, categories: any) {
  const { hero, shopClosedLoading } = useSelector(
    (state: RootState) => state.dashboard
  );

  const [orderTypeOpen, setOrderTypeOpen] = useState<boolean>(false);
  const [selectOptionOpen, setSelectOptionOpen] = useState<boolean>(false);
  const [openShopClosed, setOpenShopClosed] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedComboItems, setSelectedComboItems] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animatedProductId, setAnimatedProductId] = useState(0);
  const [browser, setBrowser] = useState('');


  const isShopClosed = (() => {
    if (!shopClosedLoading) {
      const currentTime = moment().tz('Europe/London').format('HH:mm:ss');

      return (
        hero?.isClose ||
        !(hero?.startTime < currentTime && currentTime < hero?.endTime)
      );
    }
  })();
  const AddToCartAnimation = (product: any) => {
    setIsAnimating(true);
    setAnimatedProductId(product?.productId);
    setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
  };

  useEffect(() => {
    setSelectOptionOpen(false);
    setSelectedComboItems(null);
    setSelectedProduct(null);
  }, [cart]);

  useEffect(() => {
    if (locationState?.categoryId) {
      setSelectedCategory(locationState?.categoryId);
    } else {
      if (categories && categories?.length > 0) {
        setSelectedCategory(categories?.[0]?.id);
      }
    }
  }, [categories, locationState?.categoryId]);

  const getBrowserName = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Firefox')) return 'Firefox';
    if (userAgent.includes('Edg')) return 'Microsoft Edge';
    if (userAgent.includes('Chrome')) return 'Chrome';
    if (userAgent.includes('Safari')) return 'Safari';
    if (userAgent.includes('Opera') || userAgent.includes('OPR'))
      return 'Opera';

    return 'Unknown';
  };

  useEffect(() => {
    setBrowser(getBrowserName());
  }, []);

  return {
    setOrderTypeOpen,
    orderTypeOpen,
    selectOptionOpen,
    setSelectOptionOpen,
    openShopClosed,
    setOpenShopClosed,
    selectedProduct,
    setSelectedProduct,
    selectedComboItems,
    setSelectedComboItems,
    selectedCategory,
    setSelectedCategory,
    isShopClosed,
    hero,
    isAnimating,
    setIsAnimating,
    AddToCartAnimation,
    animatedProductId,
    browser,
  };
}

export function useOpenOrderType(setOrderTypeOpen: any) {
  const { orderType } = useSelector((state: RootState) => state.order);

  useEffect(() => {
    if (!orderType?.type) {
      setOrderTypeOpen(true);
    } else {
      setOrderTypeOpen(false);
    }
  }, [orderType?.type]);

  return {
    orderType,
  };
}
