import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loading: false,
  error: null,
  email: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    storeSubscriptionRequested(
      state,
      action: PayloadAction<{ email: string }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeSubscriptionSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.subscription = action.payload;
    },
    storeSubscriptionFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  storeSubscriptionRequested,
  storeSubscriptionSuccess,
  storeSubscriptionFail,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
