import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  loadDashboardAdvertisementsRequested,
  loadDashboardHeroSettingsRequested,
  loadDashboardOffersRequested,
  loadLandingImagesRequested,
} from 'store/dashboard/dashboardSlice';
import {
  loadAcceptedReviewsFail,
  loadAcceptedReviewsRequested,
} from 'store/review/reviewSlice';

export function useLoadDashboardHeroSettings() {
  const dispatch = useDispatch();
  const onLoadHeroSettings = useCallback(async () => {
    await dispatch(loadDashboardHeroSettingsRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadHeroSettings();
    const intervalId = setInterval(onLoadHeroSettings, 60000);
    return () => clearInterval(intervalId);
  }, [onLoadHeroSettings]);
  return { onLoadHeroSettings };
}

export function useLoadDashboardReviews() {
  const dispatch = useDispatch();

  const onLoadReviews = useCallback(async () => {
    await dispatch(loadAcceptedReviewsRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadReviews();
  }, [onLoadReviews]);

  return { onLoadReviews };
}

export function useLoadDashboardOffers() {
  const dispatch = useDispatch();

  const onLoadOffers = useCallback(async () => {
    await dispatch(loadDashboardOffersRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadOffers();
  }, [onLoadOffers]);

  return { onLoadOffers };
}

export function useLoadDashboardAdvertisements() {
  const dispatch = useDispatch();

  const onLoadAdvertisements = useCallback(async () => {
    await dispatch(loadDashboardAdvertisementsRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadAdvertisements();
  }, [onLoadAdvertisements]);

  return { onLoadAdvertisements };
}

export function useLoadLandingImages() {
  const dispatch = useDispatch();

  const onLoadLandingImages = useCallback(async () => {
    await dispatch(loadLandingImagesRequested({}));
  }, [dispatch]);

  useEffect(() => {
    onLoadLandingImages();
  }, [onLoadLandingImages]);

  return { onLoadLandingImages };
}