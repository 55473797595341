import React from 'react';
import { useNavigate } from 'react-router-dom';
import HomeDark from 'assets/images/header/home-dark.svg';
import HomeLight from 'assets/images/header/home-light.svg';
import MenuDark from 'assets/images/header/menupage-dark.svg';
import MenuLight from 'assets/images/header/menupage-light.svg';
import OrderDark from 'assets/images/header/order-dark.svg';
import OrderLight from 'assets/images/header/order-light.svg';
import SignOutLight from 'assets/images/header/signout-light.svg';
import SignOutDark from 'assets/images/header/signout-dark.svg';
import ThemeDark from 'assets/images/header/theme.svg';
import ThemeLight from 'assets/images/header/theme-light.svg';
import * as routes from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthorized as useAuthorized } from 'store/auth/selector';
import { RootState } from 'store';
import { useUpdateTheme } from 'hooks/Theme';
import { AiOutlineUser } from 'react-icons/ai';
import { FiPhoneCall } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { ImageURL } from 'config';
import { setMenuOpen } from 'store/theme/themeSlice';

interface IMenuProps {
  setOpen: any;
  data: any;
  setOpenUser: any;
  setOpenUserQuery: any;
  setOpenAuthQuery: any;
}

export const Menu: React.FC<IMenuProps> = ({
  setOpen,
  data,
  setOpenUser,
  setOpenUserQuery,
  setOpenAuthQuery,
}) => {
  const navigate = useNavigate();
  const dispatch  = useDispatch();
  const isAuthorized = useSelector(useAuthorized);
  const { theme, menuOpen } = useSelector((state: RootState) => state.theme);
  const { userInfo } = useSelector((state: RootState) => state.user);
  const { onUpdateTheme } = useUpdateTheme();

  const changeTheme = () => {
    if (theme === 'dark') {
      onUpdateTheme('light');
    } else {
      onUpdateTheme('dark');
    }
  };
  const handleSignOut = () => {
    if (isAuthorized) {
      localStorage.removeItem('persist:root');
      localStorage.removeItem('persist:guest');
      localStorage.removeItem('persist:coupon');
      window.location.reload();
    } else {
      setOpenUser(true);
      setOpenUserQuery('user');
      setOpenAuthQuery('login');
    }
  };
  const userName =
    userInfo?.firstName && userInfo?.lastName
      ? `${userInfo?.firstName} ${userInfo?.lastName}`
      : 'Guest';

  return (
    <div className='absolute z-50 top-8 right-0 bg-pureBlack border border-pureWhite/30 py-4 rounded-md min-w-[14rem]'>
      <div className='px-2 text-p2 space-y-1'>
        <div className='flex items-center gap-3 rounded-md bg-cardGray/30 py-2 px-4 border-b-2 border-b-pureWhite/10'>
          {userInfo?.avatar ? (
            <img
              src={
                userInfo?.avatar?.startsWith('http')
                  ? userInfo?.avatar
                  : `${ImageURL}/${userInfo?.avatar}?v=${userInfo?.updatedAt}`
              }
              alt='email'
              className='w-6 md:w-8 h-6 md:h-8 rounded-full cursor-pointer'
            />
          ) : (
            <CgProfile className='h-6 w-6 cursor-pointer' />
          )}
          <div className='text-textGray pr-8'>
            <div className='text-p2'>{userName}</div>
            <div className='text-p3'> {userInfo?.email} </div>
          </div>
        </div>
        <div className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'>
          <div
            className='flex gap-4 items-center'
            onClick={() => [
              navigate(routes.HOME),
              dispatch(setMenuOpen(!menuOpen as any)),
            ]}
          >
            <img
              src={theme === 'dark' ? HomeDark : HomeLight}
              alt='profile'
              className='w-3.5 pb-1'
            />
            <div>Home</div>
          </div>
        </div>
        <div className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'>
          <div
            className='flex gap-4 items-center'
            onClick={() => [
              navigate(routes.MENU),
              dispatch(setMenuOpen(!menuOpen as any)),
            ]}
          >
            <img
              src={theme === 'dark' ? MenuDark : MenuLight}
              alt='menu'
              className='w-3.5 pb-1'
            />
            <div>Menu</div>
          </div>
        </div>

        {isAuthorized ? (
          <>
            <div className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'>
              <div
                className='flex gap-4 items-center'
                onClick={() => [
                  navigate(routes.ORDERS),
                  dispatch(setMenuOpen(!menuOpen as any)),
                ]}
              >
                <img
                  src={theme === 'dark' ? OrderDark : OrderLight}
                  alt='orders'
                  className='w-3.5 pb-1'
                />
                <div>Orders</div>
              </div>
            </div>

            <div className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'>
              <div
                className='flex gap-4 items-center'
                onClick={() => [
                  navigate(routes.PROFILE),
                  dispatch(setMenuOpen(!menuOpen as any)),
                ]}
              >
                <AiOutlineUser className='w-4 h-4 text-textGray' />
                <div>Profile</div>
              </div>
            </div>
          </>
        ) : null}
        <div className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'>
          <div
            className='flex gap-4 items-center'
            onClick={() => [
              navigate(routes.CONTACTUS),
              dispatch(setMenuOpen(!menuOpen as any)),
            ]}
          >
            <FiPhoneCall className='w-4 h-4 text-textGray' />
            <div>Contact US</div>
          </div>
        </div>
        <div
          className='px-4 py-2 hover:bg-pureWhite/10 cursor-pointer'
          onClick={handleSignOut}
        >
          <div className='flex gap-4 items-center'>
            <img
              src={theme === 'dark' ? SignOutDark : SignOutLight}
              alt='signout'
              className='w-3.5 pb-1'
            />
            <div>{data && data.status ? 'Sign out' : 'Sign In'}</div>
          </div>
        </div>
        <div className='px-4 py-2 cursor-pointer flex justify-between'>
          <div
            className='flex gap-4 items-center'
            onClick={() => changeTheme()}
          >
            <img
              src={theme === 'dark' ? ThemeDark : ThemeLight}
              alt='profile'
              className='w-3.5 pb-1'
            />
            <div>Dark Mode</div>
          </div>
          <label className='inline-flex items-center cursor-pointer'>
            <input
              type='checkbox'
              value=''
              className='sr-only peer'
              checked={theme === 'dark'}
              onChange={changeTheme}
            />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-100 dark:peer-focus:ring-green-900 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-green-800"></div>
          </label>
        </div>
      </div>
    </div>
  );
};
