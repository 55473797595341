import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  reviews: [],
  storeReview: null,
};

const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    storeReviewRequested(
      state,
      action: PayloadAction<{
        userId: any;
        comment: string;
        status: any;
        email: string;
        isActive: boolean;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    storeReviewSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.storeReview = action.payload;
    },
    storeReviewFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadAcceptedReviewsRequested(
      state,
      action: PayloadAction<{}>
    ) {
      state.loading = true;
      state.error = null;
    },
    loadAcceptedReviewsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.reviews = action.payload?.result;
    },
    loadAcceptedReviewsFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  storeReviewRequested,
  storeReviewSuccess,
  storeReviewFail,
  loadAcceptedReviewsFail,
  loadAcceptedReviewsRequested,
  loadAcceptedReviewsSuccess,
} = reviewSlice.actions;

export default reviewSlice.reducer;
