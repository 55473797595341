import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  loading: false,
  error: null,
  theme: 'dark',
  menuOpen: false,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateThemeRequested(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    updateThemeSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.theme = action.payload;
    },
    updateThemeFail(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    setMenuOpen: (state, action: any) => {
      state.menuOpen = action.payload;
    },
  },
});

export const {
  updateThemeRequested,
  updateThemeSuccess,
  updateThemeFail,
  setMenuOpen,
} = themeSlice.actions;

export default themeSlice.reducer;
