import React, { ReactNode, ErrorInfo } from 'react';
import Error from 'assets/images/Error.svg';
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false }; 
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true }; 
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo); 
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='flex flex-col justify-center items-center min-h-screen bg-gray-100'>
          <div className='flex justify-center gap-8 mb-8'>
            <img
              src={Error} 
              alt='under maintenance image'
              className='w-[70vw] md:w-[30vw] cursor-pointer items-center'
            ></img>
          </div>

          <h1 className='text-p2 sm:text-p1 md:text-h4 text-center font-semibold text-gray-800'>
            Oops! It seems we’ve hit a snag. Let’s sort this out right away.
          </h1>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
