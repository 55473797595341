import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  storeAddressRequested,
  storeGuestRequested,
} from 'store/guest/guestSlice';

export function useStoreGuest() {
  const dispatch = useDispatch();
  const { orderType } = useSelector((state: RootState) => state.order);
  const onStoreGuest = useCallback(
    async (values: any) => {
      await dispatch(
        storeGuestRequested({
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          mobile: values?.mobile,
        })
      );
      if (orderType?.type === 'delivery') {
        await dispatch(
          storeAddressRequested({
            postalcode: values?.postalcode,
            streetAndHouseNo: values?.streetAndHouseNo,
            locality: values?.locality,
          })
        );
      }
    },
    [dispatch]
  );
  return { onStoreGuest };
}

export function useUpdateAddress() {
  const dispatch = useDispatch();
  const onUpdateAddress = useCallback(
    async (values: any) => {
      await dispatch(storeAddressRequested(values));
    },
    [dispatch]
  );
  return { onUpdateAddress };
}
