import React, { useMemo } from 'react';
import PhoneNumberInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
interface PhoneInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
  values: string;
  errors: { [key: string]: string };
  touches: { [key: string]: boolean };
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  disableDropdown?: boolean;
  defaultCountry?: string;
}

export const NumberInput: React.FC<PhoneInputProps> = ({
  name,
  label,
  placeholder,
  className,
  onChange,
  values,
  errors,
  touches,
  onBlur,
  onFocus,
  disabled = false,
  disableDropdown = false,
  defaultCountry = 'gb',
}) => {
  const { theme } = useSelector((state: RootState) => state.theme);
  const isInvalid = useMemo(() => {
    return touches[name] && !!errors[name];
  }, [touches, errors, name]);

  return (
    <div className={className}>
      {label && (
        <label
          className={`block text-sm font-medium ${
            theme === 'dark' ? 'text-gray-50' : 'text-gray-700'
          } mb-2 `}
        >
          {label}
        </label>
      )}
      <PhoneNumberInput
        country={defaultCountry}
        value={values}
        onChange={onChange}
        inputStyle={{
          width: '100%',
          borderColor: isInvalid
            ? '#f87171'
            : 'var(--tw-border-color, #e5e7eb)',
          backgroundColor: 'transparent',
          color: 'inherit',
        }}
        inputClass={`w-full h-10 text-sm rounded-md border ${
          theme === 'dark'
            ? 'bg-black/50 border-white/30 text-gray-50'
            : 'bg-white border-black/30 text-gray-950'
          // }
          //   isInvalid
          //     ? 'border-red-500'
          //     : 'border-gray-300 dark:border-gray-500 focus:border-sky-500'
        }  rounded-md`}
        placeholder={placeholder}
        disabled={disabled}
        disableDropdown={disableDropdown}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {isInvalid && (
        <p className='text-red-500 font-medium text-[10px] mt-1'>
          {errors[name]}
        </p>
      )}
    </div>
  );
};
