export const globalMetadata = {
  title: 'Crazy Fried Chicken',
  description:
    'Enjoy the best fried chicken in the UK with fast delivery and top-quality service.',
  keywords: [
    'Fried Chicken',
    'UK Restaurant',
    'Delivery',
    'Fast Food',
    'Takeaway',
  ],
  openGraph: {
    title: 'Crazy Fried Chicken',
    description:
      'Order delicious fried chicken from Crazy Fried Chicken, your top choice in the UK.',
    url: 'https://crazyfriedchicken.co.uk',
    siteName: 'Crazy Fried Chicken',
    locale: 'en_UK',
    type: 'website',
  },
  alternates: {
    canonical: 'https://crazyfriedchicken.co.uk',
  },
  additionalMetaTags: [
    {
      name: 'author',
      content: 'SpeedCodeLabs',
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    },
  ],

  structuredData: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Crazy Fried Chicken',
    url: 'https://crazyfriedchicken.co.uk',
    logo: 'https://crazyfriedchicken.co.uk',

    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '01442 242217',
        contactType: 'Customer Service',
        areaServed: 'UK',
        availableLanguage: ['English'],
      },
    ],
  },
};
